<template>
    <properties-block v-bind="$props" v-on="$listeners">
        <b-form-group label-cols="4" :label="'Spacing|Espacement'|tr">
            <b-select v-model="block.columnGap">
                <option value="0" v-tr>None|Sans</option>
                <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-gallery #hot-reload-debug
export default {
    name: `properties-block-gallery`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
